<template>
    
    
   
 
</template>

<script>

    import User from "@/modules/user/"
    import Users from "@/modules/admin/users/"
  
    
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email } from '@validations'

    export default {

        data() {
            return {
                Users,
                current: User.self,
                is_loading: false,
                
                required,
                email,
                
                error: {
                    active: false,
                    code: -1,
                    message: null,
                    info: null,
                },
                user: null,
                form: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    group: null,
                    email: null
                }
            }
        },
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'users' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            if( perms.read === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            next( true );
            
        },
        methods: {
            preferEditAction( args ) {
                this.$refs.editModal.show();
            },
            toUSD( btc ) {
                return btc * this.$blockchain.tickers.values.USD.last;
            },
            get() {},
            edit() {
                 
            }
        },
        components: {
            ValidationProvider,
            ValidationObserver
        },
        watch: {

        },
        mounted() {
        
        }

    }

</script>